import { call, put, takeLatest, race, take, select } from 'redux-saga/effects';

import request from '@contactcentre-web/utils/request';
import {
  PREFIX as EDIT_CUSTOMER_PROFILE_PREFIX,
  EDIT_CUSTOMER_PROFILE_SUCCEEDED,
} from '@contactcentre-web/edit-customer-profile/module';

import { actions, constants, selectors } from './module';

export const getCustomerById = (customerId) => request(`/api/customers/v2/${customerId}`);

export const getCustomerStatsRequest = (sgpCustomerId) =>
  request(`/api/customers/${sgpCustomerId}/stats`);

export function* requestCustomerDetailSaga(action) {
  try {
    const response = yield call(getCustomerById, action.customerId);
    yield put(actions.getCustomerSucceeded(action.customerId, response));
  } catch (e) {
    const requestError = `${e}: No customer found with ID: ${action.customerId}`;
    yield put(actions.getCustomerFailed(requestError));
  }
}

export function* requestCustomerReloadAfterProfileUpdated({ payload }) {
  yield requestCustomerDetailSaga(payload);
}

function* requestCustomerStatsSaga({ payload: { sgpId } }) {
  try {
    const state = yield select();
    const stats = selectors.customerStats(state);

    if (stats) {
      yield put(actions.customerStatsSucceeded(stats));
      return;
    }

    const { result } = yield race({
      result: call(getCustomerStatsRequest, sgpId),
      cancelled: take(constants.CLEAR_CUSTOMER_DATA),
    });

    if (result) {
      yield put(actions.customerStatsSucceeded(result));
    }
  } catch (error) {
    yield put(actions.customerStatsError());
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_CUSTOMER_REQUESTED, requestCustomerDetailSaga);
  yield takeLatest(constants.GET_CUSTOMER_SUCCEEDED, requestCustomerStatsSaga);
  yield takeLatest(
    `${EDIT_CUSTOMER_PROFILE_PREFIX}/${EDIT_CUSTOMER_PROFILE_SUCCEEDED}`,
    requestCustomerReloadAfterProfileUpdated
  );
}
