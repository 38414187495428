// core-js and regenerator-runtime need to be the first packages to load
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { FeatureToggleSettingsProvider } from 'feature-toggle';

import '@contactcentre-web/styles/global.css';
import '@contactcentre-web/styles/trainline-font/styles.css';
import '@trainline/depot-web/css/themes/trainline/font-faces.css';
import '@trainline/depot-web/css/themes/trainline/fonts.css';
import '@trainline/depot-web/css/themes/trainline/opacity.css';
import '@trainline/depot-web/css/themes/trainline/palettes.css';
import '@trainline/depot-web/css/themes/trainline/shadows.css';
import '@trainline/depot-web/css/themes/trainline/spacers.css';
import '@trainline/depot-web/css/themes/trainline/typography.css';
import '@trainline/depot-web/css/themes/trainline/border-radius.css';
import '@trainline-shareable/css-config/lib/border-radius.css';
import '@trainline-shareable/css-config/lib/spacing.css';
import '@trainline-shareable/css-config/lib/typography.module.css';

import configureStore from './redux/store';
import IntlProviderWrapper from './components/IntlProviderWrapper';
import App from './App';

const featureFlag = (key, enabledByDefault) =>
  enabledByDefault ? localStorage.getItem(key) !== 'false' : localStorage.getItem(key) === 'true';

const FEATURES = {
  fulfilment: featureFlag('fulfilment-feature', false),
  shortenAccessTokenLifetime: featureFlag('shortenAccessTokenLifetime', false),
  paperTicket: featureFlag('paperTicket', false),
  overridingDiscretionaryRefundAmount: featureFlag('overridingDiscretionaryRefundAmount', true),
  virtualTerminalDebiting: featureFlag('virtualTerminalDebiting', true),
  discountCard: featureFlag('discountCard', false),
};

document.cookie = 'auth_source=identityServer; path=/';

const store = configureStore(FEATURES);
const { features } = store.getState();
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

ReactDOM.render(
  <Provider store={store}>
    <FeatureToggleSettingsProvider featureToggleSettings={features}>
      <IntlProviderWrapper>
        <QueryClientProvider client={queryClient}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </QueryClientProvider>
      </IntlProviderWrapper>
    </FeatureToggleSettingsProvider>
  </Provider>,
  document.getElementById('app')
);
