import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Journey } from '@contactcentre-web/redux-common/types/Journey';

import { JourneyDirection, Vendors } from './constants';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const isATOC = (vendor: string) => vendor?.toLowerCase() === Vendors.ATOC;
/*
  Logic to get the open return ticket validity
  for the ATOC journey open return will be valid for one month and Return portion is valid for 
  travel until 04:29 in the morning following the last day of validity. All travel must be completed by this time.
  For future proof all the non ATOC journey and ATOC with non P1M validity will display it based on the validityPeriod from the back end
  P1M - one month
  P1D - one day etc. 
*/
export const getOpenReturnValidityPeriod = (
  journeys: Journey[],
  outDate: Date,
  vendor: string
): Date | string | undefined => {
  const validityPeriod = journeys?.find(
    (journey) => journey?.direction === JourneyDirection.Inward
  )?.validityPeriod;
  if (!validityPeriod) return;
  if (validityPeriod === 'P1M') {
    return isATOC(vendor)
      ? dayjs(outDate).add(1, 'month').add(1, 'day').toDate()
      : dayjs.duration(validityPeriod).humanize();
  }
  return dayjs.duration(validityPeriod).humanize();
};
