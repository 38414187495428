import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SetupManagedGroup.title',
    defaultMessage: 'Set up new Managed Group user',
  },
  description: {
    id: 'SetupManagedGroup.description',
    defaultMessage:
      'As you select "Generate", this will create the following for the managed group, {managedGroupName}',
  },
  adminOption: {
    id: 'SetupManagedGroup.adminOption',
    defaultMessage: 'Admin',
  },
  readOnlyOption: {
    id: 'SetupManagedGroup.readOnlyOption',
    defaultMessage: 'Read Only role',
  },
  agentOption: {
    id: 'SetupManagedGroup.agentRoleOption',
    defaultMessage: 'An Agent role',
  },
  superAdminOption: {
    id: 'SetupManagedGroup.superAdminOption',
    defaultMessage: 'Super Admin role',
  },
  teamLeadOption: {
    id: 'SetupManagedGroup.teamLeadRoleOption',
    defaultMessage: 'A Team Lead role',
  },
  frontOfficeTeamOption: {
    id: 'SetupManagedGroup.frontOfficeTeamOption',
    defaultMessage: 'Front Office Team role',
  },
  refundTeamOption: {
    id: 'SetupManagedGroup.refundTeamOption',
    defaultMessage: 'Refund Team role',
  },
  paymentTeamOption: {
    id: 'SetupManagedGroup.paymentTeamOption',
    defaultMessage: 'Payment Team role',
  },
  fraudTeamOption: {
    id: 'SetupManagedGroup.fraudTeamOption',
    defaultMessage: 'Fraud Team role',
  },
  firstUserOption: {
    id: 'SetupManagedGroup.firstUserOption',
    defaultMessage: 'The first user',
  },
  relationTeamOption: {
    id: 'SetupManagedGroup.relationTeamOption',
    defaultMessage: 'Relation Team role',
  },
});
